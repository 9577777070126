// ==============================
// Custom style
// ==============================
.button-38 {
    background-color: #FFFFFF;
    border: 0;
    border-radius: .5rem;
    box-sizing: border-box;
    color: #111827;
    font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.25rem;
    padding: .75rem 1rem;
    text-align: center;
    text-decoration: none;
    text-decoration-thickness: auto;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    &:hover {
      background-color: rgb(249,250,251);
    }

    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
    }

    &:focus-visible {
      box-shadow: none;
    }
  }

blockquote {
      font: 14px/22px normal Helvetica, sans-serif;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 50px;
      padding-left: 15px;
      border-left: 3px solid #ccc;
      color: #333;
  }

  /* Style for all links */
  a {
      color: $single-link-color;
      text-decoration: underline;  /* Adds underline by default */
      transition: color 0.2s ease-in-out;

      [theme=dark] & {
          color: $single-link-color-dark;
      }

      &:hover {
          color: $single-link-hover-color;
          text-decoration: none;  /* Removes underline on hover */

          [theme=dark] & {
              color: $single-link-hover-color-dark;
          }
      }
  }

  /* Ensure logos fit within the menu bar */
  .theme-light-logo,
  .theme-dark-logo {
      max-height: 50px; /* Adjust height as needed */
      width: auto; /* Maintain aspect ratio */
      display: inline-block;
      vertical-align: middle;
  }

  /* Hide dark mode logo by default */
  .theme-dark-logo {
      display: none;
  }

  /* When dark mode is active, switch logos */
  [theme="dark"] .theme-dark-logo {
      display: inline-block;
  }

  [theme="dark"] .theme-light-logo {
      display: none;
  }